.ant-table-sticky-scroll {
	display: none;
}
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> thead
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> thead
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> thead
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> thead
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tbody
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tbody
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tbody
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tbody
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> th,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-content
	> table
	> tfoot
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-header
	> table
	> tfoot
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-body
	> table
	> tfoot
	> tr
	> td,
.ant-table.ant-table-bordered
	> .ant-table-container
	> .ant-table-summary
	> table
	> tfoot
	> tr
	> td {
	overflow: hidden;
}
.ant-table-filter-column {
	word-break: break-word;
}
