/* SAFARI ICON RESIZE */

.anticon > * {
	max-width: 30px !important;
	max-height: 30px !important;
}

#set-favorite {
	&,
	& * {
		> .anticon > * {
			color: #faad14;
			width: 20px !important;
			height: 20px !important;
		}
	}
}

#app-menu {
	> .anticon > *,
	* > .anticon > * {
		max-width: 14px !important;
		max-height: 14px !important;
	}
}

#app-header > .anticon > * {
	max-width: 24px !important;
	max-height: 24px !important;
}

#inside-tag {
	> .anticon > *,
	* > .anticon > * {
		max-width: 12px !important;
		max-height: 12px !important;
	}
}

.ant-card-actions {
	> .anticon > *,
	* > .anticon > * {
		max-width: 16px !important;
		max-height: 16px !important;
	}
}

.ant-alert > .anticon > * {
	max-width: 20px !important;
	max-height: 20px !important;
}

.ant-form-item-control-input-content * > .anticon > * {
	max-width: 14px !important;
	max-height: 14px !important;
}

.ant-select-selection-item-remove > .anticon > svg {
	max-width: 10px !important;
	max-height: 10px !important;
}

.ant-select-item-option-state > .anticon > svg {
	max-width: 14px !important;
	max-height: 14px !important;
}

.ant-table {
	> .anticon > *,
	* > .anticon > * {
		max-width: 25px !important;
		max-height: 25px !important;
	}
}
