#pie-anchor {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	width: 960px;
	height: 500px;
	position: relative;
}

/* svg {
	width: 100%;
	height: 100%;
} */

path.slice {
	stroke-width: 2px;
}

polyline {
	opacity: 0.3;
	stroke: black;
	stroke-width: 2px;
	fill: none;
}

.AppLayout {
	height: 100vh;
	background-color: white !important;
}

.AppLayout-header {
	background: #fff;
	height: 104px;
	font-size: 24px;
	display: flex;
	padding-left: 60px !important;
	align-items: center;
	border-bottom: 1px solid rgb(240, 240, 240);
}

.AppLayout-header-title {
	height: 40px;
	font-weight: 600;
	font-size: 32px;
	line-height: 40px;
	margin: 32px 24px 32px 24px;
}

.AppLayout-left-sider {
	border-right: 1px solid rgb(240, 240, 240);
}

.AppLayout-right-sider {
	border-left: 1px solid rgb(240, 240, 240);
}

.Content {
	background-color: #eceff6;
	padding: 24px;
	overflow-y: auto;
	scrollbar-color: #eceff6;
}

/* width */
/*::-webkit-scrollbar {
	 width: 0px;
  height: 0px; 
}*/

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e6f7ff;
  border-radius: 5px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: rgba(240, 240, 240, 0.85);
  border-radius: 10px;
} */
